<template>
  <div>
    <v-container fluid grid-list-md>
      <v-layout row class="blue-grey lighten-4">
        <v-flex shrink id="stage">
          <v-system-bar
            class="blue-grey lighten-1 white--text"
            style="white-space: pre"
            height="25"
            v-if="d.showHeader"
            >{{ header }}</v-system-bar
          >
          <v-stage
            ref="stage"
            class="blue-grey lighten-1"
            :config="stageSize"
            @mousedown="handleStageMouseDown"
            @touchstart="handleStageMouseDown"
          >
            <v-layer ref="layer" :key="layerKey">
              <!--
            <v-image
              :config="{
                image: image,
              }"
            />
						-->
              <v-rect
                :config="{
                  x: 0,
                  y: 0,
                  width: boardWidth,
                  height: 600,
                  fill: '#ECEFF1',
                }"
              />
              <v-text
                :config="{
                  x: 600,
                  y: 0,
                  text: d.showRemark ? d.remark : '',
                  padding: 20,
                  width: 200,
                  fontSize: 18,
                  fill: 'white',
                }"
              />
              <v-shape
                v-for="item in roles"
                :key="item.id"
                :config="{
                  fill: item.fill,
                  x: item.x,
                  y: item.y,
                  width: item.width,
                  height: item.height,
                  draggable: item.draggable,
                  name: item.name,
                  rotation: item.rotation,
                  sceneFunc: (context, shape) => {
                    context.beginPath();
                    context.moveTo(item.width / 4, item.height / 4);
                    context.lineTo((item.width * 3) / 4, item.height / 4);
                    context.arc(
                      item.width / 2,
                      item.height / 2,
                      item.width / 2,
                      0,
                      3.14
                    );
                    context.closePath();

                    // special Konva.js method
                    context.fillStrokeShape(shape);

                    context.textAlign = 'center';
                    context.font = 'normal 16px Verdana, sans-serif';
                    context.textBaseline = 'top';
                    context.strokeText(
                      item.name,
                      item.width / 2,
                      item.height / 2,
                      100
                    );
                  },
                  /*
                dragBoundFunc: (pos) => {
                  var newX = pos.x < 20 ? 20 : pos.x > 720 ? 720 : pos.x;
                  var newY = pos.y < 20 ? 20 : pos.y > 520 ? 520 : pos.y;
                  return {
                    x: newX,
                    y: newY,
                  };
                },
								*/
                }"
                @transformend="handleTransformEnd"
                @dragend="handleDragEnd"
              />
              <v-transformer ref="transformer" :config="configTransformer" />
            </v-layer>
          </v-stage>
          <v-system-bar
            class="blue-grey lighten-1 white--text"
            height="15"
            v-if="d.showFooter"
            >{{ footer }}</v-system-bar
          >
        </v-flex>
        <v-flex lg3 md3 xs12>
          <v-container>
            <v-layout row>
              <v-btn
                class="ma-1"
                fab
                small
                color="green"
                @click="toAdd"
                :disabled="!d.name"
                ><v-icon>add</v-icon></v-btn
              >
              <v-btn class="ma-1" fab small color="red" @click="toRemove"
                ><v-icon>remove</v-icon></v-btn
              >
              <v-btn class="ma-1" fab small color="amber" @click="toCapture"
                ><v-icon>photo_camera</v-icon></v-btn
              >
              <v-btn class="ma-1" fab small color="blue" @click="toRecall"
                ><v-icon>autorenew</v-icon></v-btn
              >
              <v-btn class="ma-1" fab small color="pink" @click="toClear"
                ><v-icon>clear</v-icon></v-btn
              >
              <v-btn class="ma-1" fab small color="cyan" @click="toTime"
                ><v-icon>alarm</v-icon></v-btn
              >
            </v-layout>
            <v-flex lg10 md12 xs12>
              <v-combobox
                label="代表"
                v-model="d.name"
                clearable
                :items="nameList"
                hide-details
              />
            </v-flex>
            <v-flex lg10 md12 xs12>
              <v-radio-group v-model="d.size" label="角色" row>
                <v-radio label="成人" value="1" />
                <v-radio label="小孩" value="2" />
              </v-radio-group>
            </v-flex>
            <v-color-picker
              class="ma-2"
              :swatches="swatches"
              show-swatches
              hide-canvas
              hide-inputs
              v-model="d.color"
            />
            <v-text-field
              label="導師"
              clearable
              v-model="d.teacher"
              @change="changeHeader"
            />
            <v-text-field
              label="案主"
              clearable
              v-model="d.client"
              @change="changeHeader"
            />
            <v-textarea
              outlined
              label="備註"
              clearable
              v-model="d.remark"
              @change="changeRemark"
            />
            <v-layout row>
              <v-checkbox
                class="ml-3 mr-3"
                label="Header"
                v-model="d.showHeader"
              />
              <v-checkbox
                class="ml-3 mr-3"
                label="Footer"
                v-model="d.showFooter"
              />
              <v-checkbox
                class="ml-3 mr-3"
                label="Remarks"
                v-model="d.showRemark"
              />
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import io from "socket.io-client";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";

const width = 800; // window.innerWidth;
const height = 600; // window.innerHeight;

export default {
  data() {
    return {
      boardWidth: 600,
      header: null,
      footer: null,
      swatches: [
        ["#FF0000", "#FF8000", "#FF8080", "#FF00EE"],
        ["#FFD080", "#D0CC00", "#00FF00", "#FF0066"],
        ["#00FF80", "#00DD00", "#008800", "#BB00FF"],
        ["#00FFFF", "#00AAAA", "#008080", "#A08080"],
      ],
      offset: 20,
      stageSize: {
        width: 800,
        height: height,
        style: {
          backgroundColor: "black",
        },
      },
      roles: [],
      nameList: ["自己", "爸爸", "媽媽", "丈夫", "妻子"],
      configTransformer: {
        resizeEnabled: false,
      },
      selectedShapeName: "",
      d: {
        teacher: null,
        client: null,
        remark: null,
        showHeader: true,
        showFooter: true,
        showRemark: false,
        name: null,
        color: null,
        size: "1",
        startTime: "",
        endTime: "",
      },
      image: null,
      socket: null,
      layerKey: 0,
    };
  },
  created() {
    /*
    const image = new window.Image();
    image.src = "/static/600px.jpg";
    image.onload = () => {
      // set image only when it is loaded
      this.image = image;
		};
		*/
    this.footer =
      "Copyright © 2021 Harvest Business Solutions. All right reserved.";
  },
  mounted() {
    const transformerNode = this.$refs.transformer.getNode();
    const stage = transformerNode.getStage();
    stage.container().style.backgroundColor = "#808080";
    //this.socket = io("http://localhost:5000");
    this.socket = io("https://family-ws.herokuapp.com/");
    /*
    this.socket = io.connect("http://localhost:5000", {
      path: "/socket.io/ws",
      autoConnect: false,
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: Infinity,
      timeout: 100000,
    });
    */
    this.socket.connect();
    this.socket.on("family-update", (data) => {
      const { name } = data;
      const idx = this.roles.map((x) => x.name).indexOf(name);
      if (idx >= 0) {
        this.roles[idx] = { ...this.roles[idx], ...data };
      } else {
        this.roles = [...this.roles, data];
      }
      this.layerKey = this.layerKey + 1;
    });
    this.socket.on("family-remove2", (data) => {
      if (data) {
        if (this.roles.find((x) => x.name == data)) {
          this.roles.splice(this.roles.map((x) => x.name).indexOf(data), 1);
          this.layerKey = this.layerKey + 1;
        }
      }
    });
  },
  beforeDestroy() {
    if (this.socket) {
      this.socket.disconnect();
    }
  },
  methods: {
    handleTransformEnd(e) {
      // shape is transformed, let us save new attrs back to the node
      // find element in our state
      const rect = this.roles.find((r) => r.name === this.selectedShapeName);
      // update the state
      rect.x = e.target.x();
      rect.y = e.target.y();
      rect.rotation = e.target.rotation();

      this.saveState();
      if (this.socket) {
        if (!this.socket.connected) {
          this.socket.connect();
        }
        this.socket.emit("family", e.target.attrs);
      }
    },
    handleStageMouseDown(e) {
      // clicked on stage - clear selection
      if (e.target === e.target.getStage()) {
        this.selectedShapeName = "";
        this.updateTransformer();
        return;
      }

      // clicked on transformer - do nothing
      const clickedOnTransformer =
        e.target.getParent().className === "Transformer";
      if (clickedOnTransformer) {
        return;
      }

      // find clicked rect by its name
      const name = e.target.name();
      const rect = this.roles.find((r) => r.name === name);
      if (rect) {
        this.selectedShapeName = name;
        this.d.name = name;
      } else {
        this.selectedShapeName = "";
        this.d.name = null;
      }
      this.updateTransformer();
    },
    updateTransformer() {
      // here we need to manually attach or detach Transformer node
      const transformerNode = this.$refs.transformer.getNode();
      const stage = transformerNode.getStage();
      const { selectedShapeName } = this;

      const selectedNode = stage.findOne("." + selectedShapeName);
      // do nothing if selected node is already attached
      if (selectedNode === transformerNode.node()) {
        return;
      }

      if (selectedNode) {
        // attach to another node
        transformerNode.nodes([selectedNode]);
      } else {
        // remove transformer
        transformerNode.nodes([]);
      }
      transformerNode.getLayer().batchDraw();
    },
    handleDragEnd(e) {
      const rect = this.roles.find((r) => r.name === e.target.name());
      // update the state
      rect.x = e.target.x();
      rect.y = e.target.y();
      rect.rotation = e.target.rotation();

      this.saveState();
      if (this.socket) {
        if (!this.socket.connected) {
          this.socket.connect();
        }
        this.socket.emit("family", e.target.attrs);
      }
    },
    toAdd() {
      if (
        !this.roles.find(
          (x) => x.name == this.d.name || x.fill == this.d.color.hexa
        )
      ) {
        const newObj = {
          name: this.d.name,
          rotation: 0,
          x: 720,
          y: 20,
          width: this.d.size == "1" ? 80 : 60,
          height: this.d.size == "1" ? 80 : 60,
          scaleX: 1,
          scaleY: 1,
          fill: this.d.color.hexa,
          draggable: true,
        };
        this.roles = [...this.roles, newObj];
        this.d.name = null;
        this.saveState();
        if (this.socket) {
          if (!this.socket.connected) {
            this.socket.connect();
          }
          this.socket.emit("family", newObj);
        }
      }
    },
    toRemove() {
      if (this.d.name) {
        this.roles.splice(
          this.roles.map((x) => x.name).indexOf(this.d.name),
          1
        );
        this.saveState();
        if (this.socket) {
          if (!this.socket.connected) {
            this.socket.connect();
          }
          this.socket.emit("family-remove", this.d.name);
        }
      }
    },
    toUpdate() {
      if (this.d.name) {
        if (!this.roles.find((x) => x.name == this.d.name)) {
          const idx = this.roles
            .map((x) => x.name)
            .indexOf(this.selectedShapeName);
          console.log({ idx });
          if (idx >= 0) {
            this.roles[idx].name = this.d.name;
          }
        }
      }
    },
    async toCapture() {
      //html2canvas(document.body).then((canvas) => {
      html2canvas(document.getElementById("stage")).then((canvas) => {
        // Export canvas as a blob
        canvas.toBlob((blob) => {
          // Generate file download
          //saveAs(blob, "screenshot.png");
          var reader = new FileReader();
          reader.onload = function(e) {
            window.location.href = reader.result;
          };
          reader.readAsDataURL(blob);

          // var blob = new Blob([response.data], { type: "application/pdf" });
          var fileURL = URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = fileURL;
          a.target = "_blank";
          a.download = "screenshot.png";
          document.body.appendChild(a);
          a.click();
        });
      });
    },
    async changeHeader() {
      this.header = `案主: ${this.d.client ? this.d.client : "-"}     導師: ${
        this.d.teacher ? this.d.teacher : "-"
      }          日期: ${moment().format("DD/MM/YYYY")} ${
        this.d.startTime ? this.d.startTime : ""
      } ${this.d.endTime ? "- " + this.d.endTime : ""}`;
    },
    changeRemark() {},
    saveState() {
      const data = JSON.stringify(this.roles);
      //console.log("data", data);
      localStorage.setItem("data", data);
    },
    toRecall() {
      const data = localStorage.getItem("data");
      this.roles = data ? JSON.parse(data) : [];
      const startTime = localStorage.getItem("startTime");
      this.d.startTime = startTime ? startTime : "";
      //console.log("data", data);
      this.layerKey = this.layerKey + 1;
      this.changeHeader();
    },
    toClear() {
      this.roles = [];
      this.d.startTime = "";
      this.d.endTime = "";
      console.log({ window });
    },
    toTime() {
      if (!this.d.startTime) {
        this.d.startTime = moment().format("HH:mm");
        localStorage.setItem("startTime", this.d.startTime);
      } else {
        this.d.endTime = moment().format("HH:mm");
      }
      this.changeHeader();
    },
  },
};
</script>
